import React from "react"
import '../css/layout.css';
import logo from '../gfx/logo.png';
import icon_user from '../gfx/icon_user.png';
import icon_package from '../gfx/icon_package.png';
import icon_check from '../gfx/icon_check.png';
import icon_link from '../gfx/icon_link.png';
import icon_mail from '../gfx/icon_mail.png';
import icon_report from '../gfx/icon_report.png';
import icon_swish from '../gfx/icon_swish.png';
import icon_qr from '../gfx/icon_qr.png';
import icon_code from '../gfx/icon_code.png';
import icon from '../gfx/icon.png';

import axios from "axios"


export default class Layout extends React.Component{


    constructor(){
        super();
        this.state = {
                    orgNr: "",
                    name: "",
                    www: "", 
                    telephone: "", 
                    contact: "",
                    email: "",
                    swish: "", 
                    errorMessage: null, 
                    message: null
        }

        this.handleOrgNrChange = this.handleOrgNrChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleWWWChange = this.handleWWWChange.bind(this);
        this.handleTelefonChange = this.handleTelefonChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSwishChange = this.handleSwishChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleErrorMessageClick = this.handleErrorMessageClick.bind(this);
        this.handleMessageClick = this.handleMessageClick.bind(this);
    }

    handleOrgNrChange(event)    { this.hideMsg(); this.setState({orgNr: event.target.value}); }
    handleNameChange(event)     { this.hideMsg(); this.setState({name: event.target.value}); }
    handleWWWChange(event)      { this.hideMsg(); this.setState({www: event.target.value}); }
    handleTelefonChange(event)  { this.hideMsg(); this.setState({telephone: event.target.value}); }
    handleContactChange(event)  { this.hideMsg(); this.setState({contact: event.target.value}); }
    handleEmailChange(event)    { this.hideMsg(); this.setState({email: event.target.value}); }
    handleSwishChange(event)    { this.hideMsg(); this.setState({swish: event.target.value}); }


    hideMsg(){
        this.setState({'message': null});
        this.setState({'errorMessage': null});
    }
    handleErrorMessageClick(event){

        this.setState({"errorMessage": null});
    }
    
    handleMessageClick(event){

        this.setState({"message": null});
    }

    handleSubmit(event) { 
        
        this.setState({errorMessage: null});
        this.setState({message: null});


    var url = "https://api.backaupp.se/register/";

    if (process.env.NODE_ENV == "development"){
        url = "http://api.backaupp.se.dev2/register/";
    }

    

        axios.post(url, {responseType: 'stream', timeout: 10000, data: 
            {   'name': this.state.name, 
                'orgNr': this.state.orgNr, 
                'www': this.state.www,
                'telephone': this.state.telephone,
                'contact': this.state.contact,
                'email': this.state.email,
                'swish': this.state.swish
             } })

        .then(res => {

            if (res.data.status == "OK"){
                this.setState({message: "Klart! Vi återkommer via epost!"});
            }else{
                //alert("FAIL: " + res.data.message);
                this.setState({errorMessage: res.data.message})
            }
            

            //alert("done");
            //requestComplete();
        })
        .catch(error => { 

            alert("FAIL: " + error); 

            /*
            if (error.response.status == 403){
                alert("Du måste logga in!")
            }else{
                alert(error); 
            }*/
            //requestComplete();
        }
        )

    }

    render(){
        
        return ( <div className='layout'>
        
        
                <div className='header'>
                    <div className='content'>
                        <div className='logo'><a href='#'><img src={logo} /></a></div>
                        <div className='navigation'>
                            <a href='#register'>Registrera</a>
                            <a href='#how'>Så fungerar det</a>
                            <a href='#footer'>Kontakt</a>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='banner'></div>
                    <div className='main'>
                        <div className='section' id='section1'>
                            <div className='sectionContent'>
                                <h1>Backa upp lokala företag!</h1>
                                <h3>Med vår tjänst kan företag snabbt och enkelt sälja stödpaket till sina stammisar!</h3>
                                <h3>Kunden betalar via swish och får en QR-kod som går att lösa in efter en viss tid.</h3>
                                <br/><br/>
                            </div>
                        </div>
                        <div className='section' id='register'>
                            <div className='sectionContent'>
                                <h1>Intresseanmälan för ert företag!</h1>
                               
                                <div className='textinput'><input type='text' value={this.state.name} placeholder='Företagsnamn' onChange={this.handleNameChange}  /></div>
                                <div className='textinput'><input type='text' value={this.state.orgNr} placeholder='Organisationsnummer' onChange={this.handleOrgNrChange} /></div>
                                {/*<div className='textinput'><input type='text' value={this.state.swish} placeholder='Swish-kontonummer' onChange={this.handleNameChange} /></div>*/}
                                <div className='textinput'><input type='text' value={this.state.www} placeholder='Webbsida' onChange={this.handleWWWChange} /></div>
                                <div className='textinput'><input type='text' value={this.state.telephone} placeholder='Telefonnummer' onChange={this.handleTelefonChange} /></div>
                                <div className='textinput'><input type='text' value={this.state.email} placeholder='E-post till kontaktperson' onChange={this.handleEmailChange} /></div>
                                <div className='textinput'><input type='text' value={this.state.contact} placeholder='Namn på kontaktperson' onChange={this.handleContactChange} /></div>
                                <div className='textinput'><input type='submit' value='SKICKA' onClick={this.handleSubmit} /></div>
                                <div className={this.state.errorMessage ? "errorMessage":"errorMessageHidden"} onClick={this.handleErrorMessageClick}> {this.state.errorMessage} </div>
                                <div className={this.state.message ? "message":"messageHidden"} onClick={this.handleMessageClick}> {this.state.message} </div>
                                <br/>
                            </div>
                        </div>
                        <div className='section' id='how'>
                            <div className='sectionContent'>
                                <h1>Såhär fungerar det</h1>
                                <h3 className='header'>För företag</h3>
                                <br/>
                                <table>
                                    <tr><td><img src={icon_user} /></td><td><p>Registera företaget ovan och verifiera via mail</p></td></tr>
                                    <tr><td><img src={icon_package} /></td><td><p>Logga in och skapa ett eller flera supportpaket
                                        <ul><li>Presentkort med ökande värde</li></ul>
                                        <ul><li>Produktpaket, ex: betala för 10 luncher få 15 luncher i höst</li></ul>
                                        <ul><li>Rabatterade produkter eller tjänster, ex: betala halva priset nu för en klippning som du gör i höst</li></ul>
                                        <br/>Företagen kan själva välja när ett paket ska bli giltigt att lösa in
                                    </p></td></tr>
                                    <tr><td><img src={icon_check} /></td><td><p>Länka via er webbsida till era supportpaket eller marknadför dem på valfritt sätt! <br/></p></td></tr>
                                    <tr><td colSpan='2'><hr/></td></tr>
                                    <tr><td><img src={icon_mail} /></td><td><p>För varje köp av QR-koder som görs får företaget en länk med referensnummer från swish och en länk för att aktivera QR-koden</p></td></tr>
                                    <tr><td><img src={icon_report} /></td><td><p>Företaget kan logga in och hämta en dagsrapport på alla köp</p></td></tr>
                                    <tr><td><img src={icon_link} /></td><td><p>I höst kan ni använda vår tjänst för att ändra saldo på QR-koder eller för att markera dem som använda i takt med att kunder löser in dem. <br/><br/>Via vårt gränssnitt får ni full koll!</p></td></tr>
  
                                </table>
                                 
                                <h3 className='header'>För kunderna</h3>
                                <br/>
                                <table>
                                    <tr><td><img src={icon_link} /></td><td><p>Kunden hittar era supportpaket via länken på företagets webbsida eller liknande</p></td></tr>
                                    <tr><td><img src={icon_package} /></td><td><p>Kunden väljer ett passande supportpaket</p></td></tr>
                                    <tr><td><img src={icon_swish} /></td><td><p>Betalning sker direkt till företagets swish-konto<br/></p></td></tr>
                                    <tr><td><img src={icon_code} /></td><td><p>Referenskoden som du får på swish-betalningen fyller du i på vår webbsida<br/></p></td></tr>
                                    <tr><td><img src={icon_check} /></td><td><p>QR-koderna skapas och du kan ladda ned dem till din mobil, du får en bekräftelse via mail<br/></p></td></tr>
                                    <tr><td colSpan='2'><hr/></td></tr>
                                    <tr><td><img src={icon_qr} /></td><td><p>Senare går du till företaget och visar upp din QR-kod<br/></p></td></tr>
                                    <tr><td><img src={icon_check} /></td><td><p>Företaget kan antingen ändra saldo på din QR-kod eller stänga den.<br/></p></td></tr>
                                    <tr><td><img src={icon_mail} /></td><td><p>Du får ett mail när din kod används eller avslutas<br/></p></td></tr>
                                </table>
                            </div>
                        </div>                   
                        <div className='section' id='cash'>
                            <div className='sectionContent'>
                                <h1>Finansiera / Sponsra </h1>
                                <h3 className='header'>För att kunna utveckla och drifta tjänsten behöver vi finansiering eller sponsring. Vill du bidra så kontakta Fredrik Petersson helst på mail: backaupp@xtc.se </h3>
                                <br/>
                                
                                 
                              
                              
                            </div>
                        </div>                    
                    </div>
                    <div className='footer' id='footer'>
                        <br/>
                        { /* sfgdfs <img className='avatar' src={icon} />*/ }
                        <h1>Om tjänsten</h1>
                        <br/>
                        <h3>Kontakta mig via <a href='https://twitter.com/freddestwitt' target='_blank'>twitter</a> eller mail: backaupp@xtc.se</h3>
                        <img className='logo' src={logo} />
                    </div>

                </div>
        
        
        
        </div>)

    }
}
