import React from 'react';
import logo from './logo.svg';
import './App.css';

import Layout from './components/Layout.js'


export default class App extends React.Component{

  render(){
    return (
     
      <div className="App" >
        <Layout />
      </div>
    );
  }

}